import React from "react";
import { motion } from "framer-motion";

function Services() {
    // services
    const services = [
        {
            icon: "📱",
            title: "Web & App Development",
            description:
                "We develop all types of web-based software and mobile apps for all platforms, devices and audiences.",
        },
        {
            icon: "🛒",
            title: "E-commerce Development",
            description:
                "We deliver E-commerce solutions for varied business models that work seamlessly on all platforms.",
        },
        {
            icon: "🤖",
            title: "AI & Blockchain Development",
            description:
                "We offer various kinds of AI solutions and Blockchain development that meet diverse business demands.",
        },
        {
            icon: "💻",
            title: "IT Consulting",
            description:
                "We conquer complex business challenges with expertise in solution development across industries.",
        },
    ];

    // Animation variants for container and items
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Quicker stagger for a more fluid effect
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, scale: 0.8 }, // Start small and faded out
        visible: {
            opacity: 1,
            scale: 1, // Scale up to full size
            transition: { duration: 0.8, ease: "easeOut" }, // Faster, smoother scaling effect
        },
    };

    return (
        <section className="bg-gray-100 py-12">
            <div className="container mx-auto text-center mb-8">
                <motion.h2
                    className="text-2xl md:text-4xl font-bold mb-4"
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: false, amount: 0.5 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                >
                    Services We Provide
                </motion.h2>
                <motion.p
                    className="text-gray-600"
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: false, amount: 0.5 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                >
                    TNK overcomes challenges, achieves results, and adds value to our clients and partners.
                    Take a look at some of our clients' success stories.
                </motion.p>
            </div>

            <motion.div
                className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.3 }}
            >
                {services.map((service, index) => (
                    <motion.div
                        key={index}
                        className="bg-white rounded-lg shadow-lg p-4 md:p-8 text-center transition transform hover:scale-105"
                        variants={itemVariants}
                    >
                        <div className="text-6xl mb-4">{service.icon}</div>
                        <h3 className="text-xl md:text-2xl font-semibold mb-4">{service.title}</h3>
                        <p className="text-gray-600">{service.description}</p>
                    </motion.div>
                ))}
            </motion.div>
        </section>
    );
}

export default Services;
