import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faTimes } from "@fortawesome/free-solid-svg-icons";

function Project() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const lastedProjects = [
        {
            title: "BD Home Group",
            category: "Web Development",
            link: "https://bdhomegroup.info",
            image: "https://bdhomegroup.info/static/media/secondimg.16762ba4bc3a223cd586.png",
        },
        {
            title: "Vietnamese Food Restaurant",
            category: "Web Development",
            link: "https://creasianlonetree.com",
            image: "https://creasianlonetree.com/static/media/Logo.63f939f6699226ed6366.png",
        },
        {
            title: "Anh Duong Logistics",
            category: "Web Development",
            link: "https://www.anhduonglogistics.com",
            image: "https://anhduonglogistics.com/userfiles/images/tongquan.png",
        },
        {
            title: "Spa Booking System",
            category: "Web & App Development",
            link: "https://nicespa.info",
            image: "https://nicespa.info/static/media/hpic.57342eed3795a7cf9ad8.png",
        },
        {
            title: "Management Machine workshop",
            category: "Web App Development",
            link: "https://www.svpmachine.com",
            image: "https://www.svpmachine.com/static/media/29.7ab161d07af2079fd50c.png",
        },
    ]
    const allProjects = [
        {
            title: "BD Home Group",
            category: "Web Development",
            link: "https://bdhomegroup.info",
            image: "https://bdhomegroup.info/static/media/secondimg.16762ba4bc3a223cd586.png",
        },
        {
            title: "Vietnamese Food Restaurant",
            category: "Web Development",
            link: "https://creasianlonetree.com",
            image: "https://creasianlonetree.com/static/media/Logo.63f939f6699226ed6366.png",
        },
        {
            title: "Anh Duong Logistics",
            category: "Web Development",
            link: "https://www.anhduonglogistics.com",
            image: "https://anhduonglogistics.com/userfiles/images/tongquan.png",
        },
        {
            title: "Spa Booking System",
            category: "Web & App Development",
            link: "https://nicespa.info",
            image: "https://nicespa.info/static/media/hpic.57342eed3795a7cf9ad8.png",
        },
        {
            title: "Management Machine workshop",
            category: "Web App Development",
            link: "https://www.svpmachine.com",
            image: "https://www.svpmachine.com/static/media/29.7ab161d07af2079fd50c.png",
        },
        {
            title: "T&T Salor",
            category: "Landing page",
            link: "https://ttbeautyspa.com/",
            image: "https://ttbeautyspa.com/static/media/10.1de8ceacc6a5f084e232.png"
        },
        {
            title: "Kim Ngan Spa",
            category: "Landing page",
            link: "https://kimnganspa.com/",
            image: "https://kimnganspa.com/static/media/img1.49fa0b0106cdded37299.png"
        },
        {
            title: "T&T Construction",
            category: "Landing page",
            link: "https://tandt-construction.com/",
            image: "https://tandt-construction.com/static/media/hayward.0af26f84336be8448b6e.png"
        },
        {
            title: "M2 Precision Machine",
            category: "Landing page",
            link: "http://m2precisionmachining.com/",
            image: "assets/images/m2.png"
        },
        {
            title: "LQ Restaurant",
            category: "Landing page",
            link: "http://aheadspavietnam.com/",
            image: "assets/images/lequy.png"
        },
        {
            title: "Happiness Nail Smurrieta",
            category: "Landing page",
            link: "https://happinessnailsmurrieta.com/",
            image: "https://happinessnailsmurrieta.com/static/media/16.49f19bfc314cb2ec17ac.png"
        }
    ];

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);

    // Effect để kiểm soát cuộn trang khi bật/tắt popup
    useEffect(() => {
        if (isPopupOpen) {
            document.body.style.overflow = "hidden"; // Vô hiệu hóa cuộn
        } else {
            document.body.style.overflow = ""; // Bật lại cuộn
        }

        // Cleanup khi component unmount
        return () => {
            document.body.style.overflow = "";
        };
    }, [isPopupOpen]);

    return (
        <section className="bg-gray-100 py-10 px-2 sm:py-16 sm:px-8" id="projects">
            <div className="container mx-auto">
                {/* Section Header */}
                <div className="flex justify-between items-center mb-6 sm:mb-8">
                    <h2 className="text-3xl sm:text-4xl font-bold text-gray-900">Latest Projects</h2>
                    <button
                        onClick={openPopup}
                        className="text-sky-500 hover:underline hover:text-sky-300 text-sm sm:text-base"
                    >
                        <strong>View all Projects <span aria-hidden="true">→</span></strong>
                    </button>
                </div>

                {/* Swiper Carousel */}
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={20}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: false,
                    }}
                    speed={10000}
                    breakpoints={{
                        640: { slidesPerView: 1 },
                        768: { slidesPerView: 2 },
                        1024: { slidesPerView: 3 },
                    }}
                    className="cursor-pointer"
                >
                    {lastedProjects.map((project, index) => (
                        <SwiperSlide key={index}>
                            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                                <img
                                    src={project.image}
                                    alt={project.title}
                                    className="w-full h-48 sm:h-64 object-cover"
                                />
                                <div className="p-2 sm:p-4">
                                    <h3 className="text-sm sm:text-xl font-semibold text-gray-900">
                                        {project.title}
                                    </h3>
                                    <p className="text-gray-600 text-sm sm:text-base">{project.category}</p>
                                    <a
                                        className="text-sky-500 font-bold underline hover:text-sky-400 hover:bg-gray-100 transition-all duration-200 inline-flex items-center"
                                        href={project.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Links
                                        <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1" />
                                    </a>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                {/* Popup */}
                {isPopupOpen && (
                    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white w-11/12 max-w-4xl p-0 rounded-lg overflow-hidden">
                            {/* Popup Header */}
                            <div className="flex justify-between items-center bg-gray-100 p-4 border-b">
                                <h3 className="text-xl font-bold text-gray-900">ALL OUR PROJECTS</h3>
                                <button
                                    onClick={closePopup}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    <FontAwesomeIcon icon={faTimes} size="lg" />
                                </button>
                            </div>

                            {/* Popup Content */}
                            <div className="p-4 overflow-y-auto max-h-[70vh]">
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {allProjects.map((project, index) => (
                                        <div
                                            key={index}
                                            className="bg-white rounded-lg shadow-lg overflow-hidden"
                                        >
                                            <img
                                                src={project.image}
                                                alt={project.title}
                                                className="w-full h-40 sm:h-48 object-cover"
                                            />
                                            <div className="p-4">
                                                <h4 className="text-lg font-semibold text-gray-900">
                                                    {project.title}
                                                </h4>
                                                <p className="text-gray-600 text-sm">
                                                    {project.category}
                                                </p>
                                                <a
                                                    className="text-sky-500 font-bold underline hover:text-sky-400 hover:bg-gray-100 transition-all duration-200 inline-flex items-center"
                                                    href={project.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Visit
                                                    <FontAwesomeIcon
                                                        icon={faExternalLinkAlt}
                                                        className="ml-1"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}

export default Project;
